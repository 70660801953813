var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"sticky-header":"100%","head-variant":"light","responsive":"","bordered":"","small":"","filter":_vm.filter,"busy":_vm.loading || _vm.updating,"stacked":"md"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center text-black",staticStyle:{"min-height":"50vh"}},[_c('b-spinner',{staticClass:"mr-3"}),_c('strong',[_vm._v(" "+_vm._s(_vm.loading ? 'Cargando información, por favor espere...' : 'Actualizando información...')+" ")])],1)]},proxy:true},{key:"cell(proyecto)",fn:function(data){return [_c('div',{staticClass:"cell--center"},[_c('b-form-select',{attrs:{"id":("proyecto_" + (data.item.id)),"options":_vm.proyectosOptions,"size":"sm"},on:{"change":function($event){return _vm.updateProyeccionPresupuesto($event, data.item)}},model:{value:(data.value.id),callback:function ($$v) {_vm.$set(data.value, "id", $$v)},expression:"data.value.id"}})],1)]}},{key:"cell(unidad_administrativa)",fn:function(data){return [_c('div',{staticClass:"cell--center"},[_c('b-form-select',{attrs:{"id":("unidad_administrativa_" + (data.item.id)),"options":_vm.unidadesAdministrativasOptions,"size":"sm"},on:{"change":function($event){return _vm.updateProyeccionPresupuesto($event, data.item)}},model:{value:(data.value.id),callback:function ($$v) {_vm.$set(data.value, "id", $$v)},expression:"data.value.id"}})],1)]}},{key:"cell(importe)",fn:function(data){return [_c('div',{staticClass:"cell--center"},[_c('x-input-group-currency',{attrs:{"item":data.item,"value":data.value,"attach-value-to-payload":"","attach-value-name":"importe","size":"sm","payload":{
            id: data.item.id,
            payload: {
              mes: data.item.mes,
              proyecto_id: data.item.proyecto.id,
              unidad_administrativa_id: data.item.unidad_administrativa.id
            }
          },"dispatch-path":"planeacionModule/editarProyeccion"},on:{"on-dispatched":_vm.onUpdatedImporte}})],1)]}},{key:"cell(acciones)",fn:function(data){return [_c('div',{staticClass:"cell--center"},[_c('u-d-controls',{attrs:{"id":data.item.id,"editPath":"","hide-edit-action":"","deletePath":"planeacionModule/eliminarProyeccion"},on:{"on-deleted":_vm.onUpdatedImporte}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }