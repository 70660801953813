<template>
  <base-view :title="getBudgetShow ? `${getBudgetShow.nombre} - ${title}` : ''">
    <b-overlay :show="isLoading">
      <template #overlay>
        <div class="text-center">
          <loading message="Cargando información, por favor espere..." />
        </div>
      </template>
      <template :class="{ isLoading }">
        <nav class="my-nav nav nav-borders">
          <router-link v-for="it in menu" :key="it.id" :to="generatePath(it.path)" class="nav-link ml-0">
            {{ it.meta.title }}
          </router-link>
        </nav>

        <hr class="mt-0 mb-4" />

        <component :is="tab" :id="routeId"/>
      </template>
    </b-overlay>
  </base-view>
</template>

<script>
import { capituloMilViews as menu } from '@/router/routes'
import importacion from '@/components/Planeacion/PlaneacionImportacionForm'
import proyeccion from '@/components/Planeacion/ProyeccionTable'
import analisis from '@/components/Planeacion/AnalisisTable'

export default {
  name: 'PlaneacionView',

  components: {
    importacion,
    proyeccion,
    analisis
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    tab: {
      type: String,
      default: 'importacion'
    }
  },

  mounted () {
    this.init()
    this.$store.commit('UIModule/setSidebarActive', false)
  },

  computed: {
    routeId () {
      return parseInt(this.$route.params.id)
    },
    getBudgetShow () {
      return this.$store.getters['planeacionModule/getBudgetShow']
    }
  },

  data () {
    return {
      menu,

      isLoading: false
    }
  },

  methods: {
    generatePath (path) {
      return path.replace(':id', this.routeId)
    },
    async getBudgetById (id) {
      this.isLoading = true
      await this.$store.dispatch('planeacionModule/getBudgetPlanById', { id, type: 'show' })
      this.isLoading = false
    },
    init () {
      this.getBudgetById(this.routeId)
    }
  }
}
</script>
