/*
 * Mixin usado para compartir funciones entre vistas,
 * para la importación, proyección y generación de análisis.
 */
export default {
  methods: {
    async $getPeriodoFiscalAndFuenteFinanciamiento (
      module = 'planeacionModule',
      fuente = 'fuente_financiamiento_por_defecto'
    ) {
      //
      // Obtiene el periodo fiscal actualmente activo
      // const periodo = await this.$getCurrentPeriodoFiscal(module)
      const periodo = await this.$getCurrentPeriodoFiscal()

      //
      // Selecciona la fuente de financiamiento por defecto
      return this.$getCurrentFuenteFinaciamiento(module, fuente, periodo.id)
    },

    /**
     * Obtiene el periodo fiscal actualmente activo
     */
    async $getCurrentPeriodoFiscal (module = 'planeacionModule') {
      const periodo = await this.$getPeriodoFiscalActivo()

      this.$store.commit(`${module}/setPeriodoFiscal`, periodo)

      return periodo
    },

    /**
     * $getCurrentFuenteFinaciamiento.
     *
     * Su funcionamiento está algo "oscuro", (siempre olvido cómo funciona).
     *
     * El funcionamiento es obtiene la fuente de financiamiento por defecto,
     * desde el módulo de fuentes de financiamiento.
     *
     * Las fuentes de financiamiento se manejan desde un "panel de control",
     * donde desde el backend se realiza la consulta para saber cuál está por defecto.
     *
     * La idea de programarlo de ésta manera es que se obtiene la fuente de financiamiento,
     * después dentro del módulo se debe declarar en un state, una variable para mutar
     * y guardar la información de la fuente de financiamiento.
     *
     * La intención es que cada que se realice una consulta en el módulo indicado donde
     * se require un periodo fiscal, simplemente se hiciera la consulta a ésta función,
     * y obtener los datos desde el state para mandarlos como payload.
     *
     * ENHANCEMENT: Pensándolo bien creo que se podría hacer lo mismo, pero consultando
     * desde el módulo de fuentes de financiamiento, que es el responsable de este concepto,
     * si hacer commits a otros módulos que no tienen esa responsabilidad.
     *
     * @param {*} module Nómbre del módulo a donde se hará el commit.
     * @param {*} fuenteDefault Nombre de la fuente financiamiento para obtener su valor.
     *
     * @returns Retorna el objeto con la información de la fuente de financiamiento.
     */
    async $getCurrentFuenteFinaciamiento (
      module = 'planeacionModule',
      fuente = 'fuente_financiamiento_por_defecto',
      periodo_fiscal_id
    ) {
      const getval = await this.$store.dispatch('fuenteFinanciamientoModule/getDefault', {
        fuente,
        periodo_fiscal_id
      })

      this.$store.commit(`${module}/setFuenteFinanciamiento`, getval.data)

      return getval.data
    }
  }
}
