<template>
  <div>
    <b-form @submit.prevent="submit">
      <b-alert :show="processed" :variant="variant">
        {{ msg }}
      </b-alert>

      <!-- Fuente de Financiamiento -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Fuente de Financiamiento
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.fuente_financiamiento_id"
          :options="fuentes_financiamiento_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Unidad Administrativa -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Unidad Administrativa
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.unidad_administrativa_id"
          :options="unidades_administrativas_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Proyecto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Proyecto
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.proyecto_id"
          :options="proyectos_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Mes -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Mes
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.mes"
          :options="meses_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Importe -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Importe
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-input v-model="payload.importe"></b-form-input>
      </b-form-group>

      <!-- Submit -->
      <div class="d-flex justify-content-between">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>
        <b-button type="submit" size="sm" variant="success" :disabled="!isFormVal">
          {{ 'Guardar' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>

import { EventBus } from '../../EventBus'

const payload = () => ({
  periodo_fiscal_id: null,
  presupuesto_id: null,
  fuente_financiamiento_id: null,
  unidad_administrativa_id: null,
  proyecto_id: null,
  prestacion_id: null,
  mes: null,
  importe: null
})

export default {
  name: 'AnalisisDesglosePartidaForm',

  created () {
    this.init()
  },

  props: {
    prestacion_id: null,
    periodo_fiscal_id: null,
    presupuesto_id: null,
    fuente_financiamiento_id: null
  },

  data () {
    return {
      payload: payload(),

      fuentes_financiamiento_list: [],

      unidades_administrativas_list: [],

      proyectos_list: [],

      meses_list: [
        { text: 'Enero', value: 1 },
        { text: 'Febrero', value: 2 },
        { text: 'Marzo', value: 3 },
        { text: 'Abril', value: 4 },
        { text: 'Mayo', value: 5 },
        { text: 'Junio', value: 6 },
        { text: 'Julio', value: 7 },
        { text: 'Agosto', value: 8 },
        { text: 'Septiembre', value: 9 },
        { text: 'Octubre', value: 10 },
        { text: 'Noviembre', value: 11 },
        { text: 'Diciembre', value: 12 }
      ],

      prestacion: null,

      variant: 'info',
      processed: false,
      msg: '',

      loading: false
    }
  },

  computed: {
    isFormVal () {
      return [
        Boolean(this.payload.fuente_financiamiento_id),
        Boolean(this.payload.unidad_administrativa_id),
        Boolean(this.payload.proyecto_id),
        Boolean(this.payload.importe)
      ].every(e => e)
    }
  },

  methods: {
    init () {
      //
      // Carga el periodo fiscal
      this.payload.periodo_fiscal_id = this.periodo_fiscal_id
      this.payload.presupuesto_id = this.presupuesto_id
      this.payload.prestacion_id = this.prestacion_id
      this.payload.fuente_financiamiento_id = this.fuente_financiamiento_id

      //
      // Inicializa mensajes
      this.showMessage()

      //
      // Carga listadoss
      this.load()
    },

    async load () {
      this.loading = true

      // Obtenemos fuentes de financiamiento
      const fuenteslist = await this.$store.dispatch('fuenteFinanciamientoModule/getFuentesFinanciamientoList',
        this.payload.periodo_fiscal_id)
      this.fuentes_financiamiento_list = fuenteslist.map(f => ({ value: f.id, text: f.clave + ' ' + f.nombre }))

      // Obtenemos unidades administrativas
      const unidadeslist = await this.$store.dispatch('administrativeUnitModule/getUnidadesAdministrativasList',
        this.payload.periodo_fiscal_id)
      this.unidades_administrativas_list = unidadeslist.map(f => ({ value: f.id, text: f.clave + ' ' + f.nombre }))

      // Obtenemos proyectos
      const proyectoslist = await this.$store.dispatch('proyectoModule/getProyectosList',
        this.payload.periodo_fiscal_id)
      this.proyectos_list = proyectoslist.map(f => ({ value: f.id, text: f.clave + ' ' + f.nombre }))

      // Obtenemos prestación
      this.prestacion = await this.$store.dispatch('prestacionesModule/getItemById',
        this.payload.prestacion_id)

      this.loading = false
    },

    async submit () {
      this.loading = true
      const getval = await this.$store.dispatch('planeacionModule/updateAnalisisPresupuestoDesgloseItem', this.payload)
      this.loading = false

      //
      // Muestra una alerta si ha ocurrido un error
      this.processed = true
      this.msg = getval.message
      this.variant = getval.error ? 'danger' : 'success'

      if (getval.error) return

      this.showMessage(getval.message)
      this.emitResult(getval)
      this.clearForm()

      EventBus.$emit('on-change-analisis-desglose-partida-importe')
    },

    showMessage (msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: 'Correcto',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },

    clearForm () {
      this.payload = payload()

      this.processed = false
      this.variant = 'info'
      this.msg = ''
      this.input_fecha = null
      this.$store.commit('momentosContablesModule/setCurrentNomina', null)
    },

    emitResult (data) {
      this.$emit('on-result', data)
    }
  }
}
</script>
