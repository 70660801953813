var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulateForm',{ref:"proyeccionImporteForm",on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"name":"proyecto","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
        required: 'El proyecto es requerido'
      }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Proyecto","required":""}},[_c('check-authorization',{attrs:{"requiresAuthorizations":['listar proyectos'],"show-alert":false,"no-slots":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var authorized = ref.authorized;
      var message = ref.message;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",modifiers:{"top":true}}],attrs:{"title":authorized ? '' : message}},[_c('x-select',{ref:"xselectproyectos",attrs:{"dispatchPath":null,"getterListPath":"proyectoModule/getAllProyectos","formatListWith":['id', 'nombre'],"no-validate":"","no-prepend":""},model:{value:(_vm.formValues.proyecto_id),callback:function ($$v) {_vm.$set(_vm.formValues, "proyecto_id", $$v)},expression:"formValues.proyecto_id"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Selecciona un proyecto -- ")])],1)],1)]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.proyecto_id),callback:function ($$v) {_vm.$set(_vm.formValues, "proyecto_id", $$v)},expression:"formValues.proyecto_id"}}),_c('FormulateInput',{attrs:{"name":"unidad_administrativa","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
        required: 'La unidad administrativa es requerida'
      }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Unidad administrativa","required":""}},[_c('check-authorization',{attrs:{"requiresAuthorizations":['listar unidades administrativas'],"show-alert":false,"no-slots":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var authorized = ref.authorized;
      var message = ref.message;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",modifiers:{"top":true}}],attrs:{"title":authorized ? '' : message}},[_c('x-select',{ref:"xselectunidades",attrs:{"dispatchPath":null,"getterListPath":"administrativeUnitModule/getAdministrativeUnits","formatListWith":['id', 'nombre'],"no-validate":"","no-prepend":""},model:{value:(_vm.formValues.unidad_administrativa_id),callback:function ($$v) {_vm.$set(_vm.formValues, "unidad_administrativa_id", $$v)},expression:"formValues.unidad_administrativa_id"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Selecciona una unidad administrativa -- ")])],1)],1)]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.unidad_administrativa_id),callback:function ($$v) {_vm.$set(_vm.formValues, "unidad_administrativa_id", $$v)},expression:"formValues.unidad_administrativa_id"}}),_c('FormulateInput',{attrs:{"name":"mes","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
        required: 'El mes es requerido'
      }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Selecciona un mes","required":""}},[_c('x-select',{attrs:{"getterListPath":"UIModule/getMonths","formatListWith":['id', 'value'],"no-validate":"","no-prepend":""},model:{value:(_vm.formValues.mes),callback:function ($$v) {_vm.$set(_vm.formValues, "mes", $$v)},expression:"formValues.mes"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Selecciona un mes -- ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.mes),callback:function ($$v) {_vm.$set(_vm.formValues, "mes", $$v)},expression:"formValues.mes"}}),_c('FormulateInput',{attrs:{"name":"importe","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
        required: 'El importe es requerido'
      }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Importe","required":""}},[_c('b-row',[_c('b-col',[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formValues.importe),callback:function ($$v) {_vm.$set(_vm.formValues, "importe", _vm._n($$v))},expression:"formValues.importe"}})],1),_c('b-col',[_c('b-form-input',{attrs:{"readonly":"","value":_vm.$formatNumber(_vm.formValues.importe)}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.importe),callback:function ($$v) {_vm.$set(_vm.formValues, "importe", $$v)},expression:"formValues.importe"}}),_c('x-form-footer-buttons',{attrs:{"disabled":hasErrors || isLoading,"loading":isLoading,"isEditing":false,"isCreating":true},on:{"on-click-create":_vm.submit},scopedSlots:_vm._u([{key:"cancel",fn:function(){return [_vm._t("btn-cancel-place")]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }