<template>
  <div
    class="cell__container rounded px-2 my-cursor"
    :class="modified ? 'cell--modified' : ''"
    style="min-height: 80px"
    @click="onClickElement"
  >
    <div v-if="isObject(row.item)" class="cell__content">
      <b-badge variant="primary">
        Recurso Estatal: {{ $formatNumber(row.value['Recurso Estatal']) }}
      </b-badge>
      <b-badge variant="success">
        Ingresos Propios: {{ $formatNumber(row.value['Recursos Propios']) }}
      </b-badge>
    </div>
    <span v-else class="cell__value">{{ row.value }}</span>

    <!--
      @Modal

      Para mostrar el contenido de la celda
      en un formulario para la separación de importes
    -->
    <x-modal id="analisis-importes" v-model="modal" title="Separación de importes" with-filter #default="{ filter }"
             v-on:close="close" v-on:ok="close"
    >
      <b-container fluid>
        <analisis-desglose-partida-table :item="item" :filter="filter" />
      </b-container>
    </x-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import AnalisisDesglosePartidaTable from '@/components/Planeacion/AnalisisDesglosePartidaTable'

const RECURSO_ESTATAL = 'Recurso Estatal'
const INGRESO_PROPIO = 'Recursos Propios'

export default {
  name: 'AnalisisCell',

  components: {
    AnalisisDesglosePartidaTable
  },

  props: {
    row: {}
  },

  data () {
    return {
      modified: null,

      item: {},

      modal: false
    }
  },

  methods: {
    onClickElement () {
      if (!this.isObject(this.row.value)) return

      const mes = _.upperCase(this.row.field.key)
      const monthFounded = _.find(this.$store.getters['UIModule/getMonths'], { value: mes })

      //
      // Agrega el item al state para
      // pasarlo como prop a otro componente modal.
      this.item.recursoEstatal = this.row.value[RECURSO_ESTATAL]
      //
      this.item.ingresoPropio = this.row.value[INGRESO_PROPIO]
      //
      this.item.global = this.row.value.Global
      //
      // Agrega el id del mes
      this.item.mes = monthFounded.id
      //
      // Agrega el nombre del mes
      this.item.strMes = this.row.field.key
      //
      // Agrega la prestación
      this.item.prestacion_id = this.row.item.prestacion_id
      //
      // Agrega el periodo fiscal
      this.item.periodo_fiscal_id = this.row.item.periodo_fiscal_id
      //
      // Agrega el presupuesto id
      this.item.presupuesto_id = this.row.item.presupuesto_id

      //
      // Muestra el modal
      this.modal = true
    },

    isObject (value) {
      return _.isObject(value)
    },

    close (event) {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss">
.cell__container {
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-content: space-between;

  &:hover {
    background-color: rgb(194, 202, 216) !important;
  }
}

.cell__content {
  min-height: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
}

.cell__value {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cell--modified {
  background-color: rgb(253, 201, 201) !important;
}
</style>
